import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Ads from 'vue-google-adsense';
import App from './App.vue';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

// eslint-disable-next-line
Vue.use(require('vue-script2'));
Vue.use(Ads.Adsense);
Vue.use(Ads.InArticleAdsense);
Vue.use(Ads.InFeedAdsense);
Vue.use(VueI18n);

const ja = {
  // About
  'About Regular Clipboard Cleaner': 'Regular Clipboard Cleaner について',
  'Regular Clipboard Cleaner is an application that clears the clipboard regularly.':
    'クリップボード履歴の管理とクリップボードを定期的にクリアするアプリです',
  'This application clears the clipboard periodically to reduce information leakage from the clipboard.':
    'このアプリを使うことでクリップボードを定期的にクリアし、クリップボードからの情報漏洩を軽減します。',
  'You can also manage the clipboard history and restore the cleared text.':
    'また、クリップボードの履歴を維持し、クリアした内容を復元することが可能です。',
  // Features
  Features: '特徴',
  'Periodic Clear': '定期クリア',
  'The clearing interval can be changed, and can be set from 1 second to a value as large as over a day.':
    'クリア間隔は変更することが可能で、1秒から1日を超えるような大きい値を設定することが可能です。',
  'By specifying a small value, you can practically disable the clipboard function, and by specifying a large value, you can use it only as a clipboard history management application.':
    '小さい値を指定することで実質的にクリップボードの機能を無効化することが可能で、大きい値を指定することでクリップボード履歴管理アプリのみとして利用することが可能です。',
  'Clipboard History': 'クリップボード履歴',
  'After the application is launched, it monitors the clipboard and saves the history.':
    'アプリ起動後はクリップボードを監視して履歴を保存します。',
  'When you select the history, you can copy it to the clipboard for reuse.':
    '履歴を選択するとクリップボードにコピーして再利用することが可能です。',
  'It is possible to set the number of history to be saved, and this feature can be disabled by setting it to 0.':
    '履歴を保存する件数を設定することが可能で、0を設定することでこの機能を無効化することが可能です。',
  'Edit History Text': '履歴のテキストを編集',
  'It is possible to edit the text when selecting the history and copying it to the clipboard.':
    '履歴を選択してクリップボードにコピーするときのテキストを編集することが可能です。',
  'The programming language for editing is JavaScript.':
    '編集するためのプログラミング言語はJavaScriptです。',
  'You can build your own editing process according to any condition, such as when you select a history while pressing a certain key, or when it contains a certain keyword.':
    '特定のキーを押しながら履歴を選択した場合、特定のキーワードを含んでいる場合など、あらゆる条件に応じて独自に編集処理を構築することが可能です。',
  // Details
  Details: '詳細',
  // Auto Start
  'Auto Start': '自動起動',
  'The application will start automatically when you log in. You can also disable it.':
    'ログインするとアプリは自動起動します。無効化することも可能です。',
  // Shortcut
  Shortcut: 'ショートカット',
  'By setting up a shortcut, the clipboard history window can be displayed immediately.':
    'ショートカットを設定することでクリップボード履歴画面を即座に表示することが可能です。',
  // Clipboard History Settings
  'Clipboard History Settings': 'クリップボード履歴の設定',
  'In some cases, such as when sharing a PC, you may not want to save the clipboard history permanently.':
    'PCを共有している場合など、クリップボード履歴を永続的に保存したくないケースがあると思います。',
  'Therefore, by default, the clipboard history will be cleared when the application is closed.':
    'そのため、デフォルトではアプリを終了したらクリップボード履歴はクリアします。',
  'If you want to keep the clipboard history even after restarting the application, you need to change the settings.':
    'アプリを再起動してもクリップボード履歴を維持したい場合は設定を変更する必要があります。',
  // About Pasting
  'About Pasting': '貼り付け機能について',
  'You can paste text from the clipboard history window when you select it.':
    'クリップボード履歴画面からテキストを選択したときに張り付けることが可能です。',
  'This feature does not work on macOS.': 'この機能はmacOSでは動きません。',
  'It is also possible to execute any command and launch external applications in case the paste function of the application does not work.':
    '任意のコマンドの実行することも可能で、アプリの貼り付け機能が動かない場合などを考慮して外部アプリを起動することが可能です。',
  'If you are using AutoHotkey, you can create the following script and specify the ahk file in the command to paste the text after copying.':
    'AutoHotkeyを使用している場合、以下のスクリプトを作成し、コマンドに ahk ファイルを指定すれば、コピー後にテキストを貼り付けることが可能です。',
  // Theme
  Theme: 'テーマ',
  'It is possible to switch between dark and light themes.':
    'ダークテーマとライトテーマを切り替えることが可能です。',
  // Block List
  'Block List': 'ブロックリスト',
  'It is possible to set the text that contains certain keywords not to be saved in the history.':
    '特定のキーワードが含まれるテキストを履歴に保存しないように設定することが可能です。',
  // Multilingual Support
  'Multilingual Support': '多言語サポート',
  'Only English and Japanese are switched based on the OS language settings. There is no setting function.':
    'OSの言語設定をもとに英語と日本語のみ切り替えています。設定機能はありません。',
  // Cross Platform
  'Cross Platform': 'クロスプラットフォーム',
  'The application is built with Electron and can run on Windows and macOS.':
    'アプリはElectronで作成されており、Windows, macOSで動作させることが可能です。',
  // Open Source
  'Open Source': 'オープンソース',
  'The source is available on GitHub.':
    'ソースコードはGitHub上で公開しています。',
  'If you are not satisfied with the default functionality, you can modify the source code and build it.':
    'デフォルトの機能に満足いかない場合はソースコードを変更してビルドすることも可能です。'
};

const en = Object.keys(ja).reduce(
  (accumulator: { [kye: string]: string }, currentValue) => {
    accumulator[currentValue] = currentValue;
    return accumulator;
  },
  {}
);

new Vue({
  vuetify,
  render: (h) => h(App),
  i18n: new VueI18n({
    locale: navigator.language ? navigator.language.substr(0, 2) : 'en',
    fallbackLocale: 'en',
    messages: { en, ja }
  })
}).$mount('#app');

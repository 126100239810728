





















































































































































































































































































































































































































import Vue from 'vue';

const script = String.raw`// Sample editing script
(text, event) => {
  if (event.ctrlKey) {
    event.preventPaste(); // Call this if you don't want to paste.
  }
  if (event.shiftKey) {
    const m = text.match(/\r\n|\n|\r/);
    return m
      ? text
          .split(m[0])
          .map(str => '> ' + str)
          .join(m[0])
      : '> ' + text;
  }
  if (event.key === 'u') {
    return text.toUpperCase();
  }
  if (event.key === 'l') {
    return text.toLowerCase();
  }
  return text;
}`;

export default Vue.extend({
  name: 'App',

  components: {},

  data: () => ({
    //
  }),

  computed: {
    script: () => script
  }
});
